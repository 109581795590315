import React, { useEffect, useState, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import {
    reduxForm,
    Field,
    formValueSelector,
    isPristine,
    FieldArray,
    getFormValues} from 'redux-form';
import { Card, Button } from 'reactstrap';
import queryString from 'query-string';
import classNames from 'classnames/bind';
import { Trans } from '@lingui/macro';
import { useI18n } from '../providers/I18nProvider';

import Checkbox from './elements/Checkbox';
import RFReactSelect from './elements/RFReactSelect';
import ColorPicker from './elements/ColorPicker';
import RFReactSelectAsync from './elements/RFReactSelectAsync';
import Input from './elements/Input';
import ServicesAPI from '../api/services';
import translations from '../translations';
import { URL_REGEX } from '../constants';

import styles from './Configurator.module.css';
import getFormInitialValues from '../utils/getFormInitialValues';
import { withRouter } from 'react-router';


const cx = classNames.bind(styles);

export let Configurator = (props) => {
    const {
        dispatch,
        handleSubmit,
        error,
        selectedCompanyId,
        selectedBookLayout,
        selectedTimesLayout,
        pristine,
        invalid,
        change,
        name
    } = props;
    const { language } = useI18n();

    let timeLayouts;
    if (selectedBookLayout === 'service-based') {
        timeLayouts = [
            { value: 'week', label: <Trans>Vecka</Trans> },
            { value: 'month', label: <Trans>Månad</Trans> },
            { value: 'quarter', label: <Trans>Kvartal</Trans> },
            {
                value: 'list',
                label: <Trans>Lista (Använd denna endast om du har fåtalet tider)</Trans>,
            },
        ];
    } else {
        timeLayouts = [
            { value: 'month', label: <Trans>Månad</Trans> },
            { value: 'quarter', label: <Trans>Kvartal</Trans> },
            { value: 'weekly-overview', label: <Trans id="weeklyOverview">Weekly overview</Trans> },
        ];
    }

    const i18n = useI18n();
    const [servicesOptions, setServicesOptions] = useState([]);
    const [articlesOptions, setArticlesOptions] = useState([]);
    const [company, setCompany] = useState(null);
    const formValues = useSelector(getFormValues('configuration'));

    useEffect(() => {
        if(formValues.serviceIdsToShow && formValues.serviceIdsToShow.some(value => value === '')) {
            const modifiedServiceOptions = servicesOptions.map(option => ({...option, disabled: true }));
            setServicesOptions(modifiedServiceOptions);
        }
        
        if (!formValues.serviceIdsToShow) {
            const modifiedServiceOptions = servicesOptions.map(option => ({...option, disabled: false }));
            setServicesOptions(modifiedServiceOptions);
            props.dispatch(change('serviceIdsToShow', []));
        }
        
        if(formValues.articleIdsToShow && formValues.articleIdsToShow.some(value => value === '')) {
            const modifiedArticleOptionsOptions = articlesOptions.map(option => ({...option, disabled: true }));
            setArticlesOptions(modifiedArticleOptionsOptions);
        }
        
        if (!formValues.articleIdsToShow) {
            const modifiedArticleOptionsOptions = articlesOptions.map(option => ({...option, disabled: false }));
            setArticlesOptions(modifiedArticleOptionsOptions);
            props.dispatch(change('articleIdsToShow', []));
        }

    }, [formValues.articleIdsToShow, formValues.serviceIdsToShow ]);

    const preselectedServiceOptions = servicesOptions.filter(option => option.value);

    useEffect(() => {
        if (selectedCompanyId) {
            ServicesAPI.getServices(selectedCompanyId)
                .then((response) => {
                    const options = response.Results.map((service) => ({
                        value: service.Id.toString(),
                        label: service.Name,
                    }));

                    options.push({
                        value: 'none',
                        label: <Trans id="serviceOption.none" />
                    })

                    setServicesOptions(options);
                })
                .catch((error) => {
                    console.error(error);
                });
            
            ServicesAPI.getArticles(selectedCompanyId)
                .then((response) => {
                    const options = response.Results.map((article) => ({
                        value: article.Id.toString(),
                        label: article.Name,
                    }));

                    options.push({
                        value: 'none',
                        label: <Trans id="articleOption.none" />
                    })

                    setArticlesOptions(options);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [selectedCompanyId]);

    useEffect(() => {
        if (selectedCompanyId) {
            ServicesAPI.getCompany(selectedCompanyId)
                .then((response) => {
                    setCompany(response.Results[0]);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [selectedCompanyId]);

    const handlePrimaryColorChange = useCallback(
        (color) => {
            change('primaryColor', color.hex);
        },
        [change]
    );

    const handleLanguageChange = (value) => {
        props.dispatch(change('configuration', 'language', value));
    };

    return (
        <form className={cx('form')} onSubmit={handleSubmit}>
            <div className={cx('form-body')}>
                {!selectedCompanyId && (
                    <Card body style={{ marginBottom: 10 }}>
                        <Field
                            getOptions={(input, callback) => {
                                ServicesAPI.getCompanies(input)
                                    .then((response) => {
                                        const options = response && response.Results ? response.Results.map((company) => {
                                            return {
                                                value: company.Id,
                                                label: company.Name,
                                            };
                                        }) : [];

                                        callback(options);
                                    })
                                    .catch((error) => {
                                        callback(error);
                                    });
                            }}
                            label="ID"
                            name="company"
                            className="form-component"
                            component={RFReactSelectAsync}
                        />
                    </Card>
                )}
                {selectedCompanyId && company && (
                    <h4 className={cx('company-name')}>{company.Name}</h4>
                )}
                <h6>
                    <Trans>Tid</Trans>
                </h6>
                <Card body style={{ marginBottom: 10 }}>
                    <Field
                        label={<Trans>Tidsvisning</Trans>}
                        className="form-component"
                        name="timesLayout"
                        options={timeLayouts}
                        component={RFReactSelect}
                    />
                    <Field
                        disabled={selectedBookLayout !== 'service-based'}
                        label={<Trans>Visa nästa lediga tid</Trans>}
                        className="form-component"
                        name="showNextAvailableTime"
                        type="checkbox"
                        component={Checkbox}
                    />
                </Card>
                <h6>
                    <Trans>Bokning</Trans>
                </h6>
                <Card body style={{ marginBottom: 10 }}>
                    <Field
                        multi
                        label={<Trans>Tillåtna bokningssätt</Trans>}
                        className="form-component"
                        name="bookMethods"
                        options={[
                            {
                                value: 'contact-information',
                                label: <Trans>Med kontaktuppgifter</Trans>,
                            },
                            { value: 'login', label: <Trans>Med login</Trans> },
                            {
                                value: 'login-facebook',
                                label: <Trans>Med login (Facebook)</Trans>,
                            },
                            {
                                value: 'create-account',
                                label: <Trans id="createAccount" />,
                            },
                        ]}
                        component={RFReactSelect}
                    />
                    <Field
                        label={<Trans>Bokningsflöde</Trans>}
                        className="form-component"
                        name="bookLayout"
                        options={[
                            { value: 'service-based', label: <Trans>Tjänstbaserat</Trans> },
                            { value: 'time-based', label: <Trans>Tidsbaserat</Trans> },
                            { value: 'resource-based', label: <Trans>Resursbaserat</Trans> },
                        ]}
                        component={RFReactSelect}
                        onChange={(event, value) => {
                            if (value !== 'service-based') {
                                if (selectedTimesLayout === 'week') {
                                    props.dispatch(change('configuration', 'timesLayout', 'month'));
                                }

                                props.dispatch(
                                    change('configuration', 'showNextAvailableTime', false)
                                );
                            }
                        }}
                    />
                </Card>
                <h6>
                    <Trans>Utseende</Trans>
                </h6>
                <Card body style={{ marginBottom: 10 }}>
                    <div>
                        <Field
                            label={<Trans>Listningslayout</Trans>}
                            className="form-component"
                            name="listingLayout"
                            options={[
                                { value: 'columns', label: <Trans>Kolumnbaserad</Trans> },
                                { value: 'rows', label: <Trans>Radbaserad</Trans> },
                                { value: 'rows-compact', label: <Trans>Radbaserad enkel</Trans> },
                                {
                                    value: 'row-based-grouped',
                                    label: <Trans id="rowBasedGrouped" />,
                                },
                            ]}
                            component={RFReactSelect}
                        />
                        <Field
                            label={<Trans>Göm bild för tjänst</Trans>}
                            className="form-component"
                            name="hideServiceImage"
                            type="checkbox"
                            component={Checkbox}
                        />
                        <Field
                            label={<Trans id="configurator.hideServiceInfo" />}
                            className="form-component"
                            name="hideServiceInfo"
                            type="checkbox"
                            component={Checkbox}
                        />
                        <Field
                            label={<Trans>Visa sluttiden på tidsslot.</Trans>}
                            className="form-component"
                            name="showEndTimeOnTimeslots"
                            type="checkbox"
                            component={Checkbox}
                        />
                        <Field
                            label={<Trans>Text på bokad tidsslot</Trans>}
                            className="form-component"
                            name="bookedTimeSlotText"
                            type="text"
                            component={Input}
                        />
                        <Field
                            label={<Trans>Språk</Trans>}
                            className="form-component"
                            name="language"
                            options={[
                                { value: 'sv', label: <Trans>Svenska</Trans> },
                                { value: 'en', label: <Trans>English</Trans> },
                                { value: 'fi', label: <Trans>Suomi</Trans> },
                                { value: 'no', label: <Trans>Norsk</Trans> },
                            ]}
                            component={RFReactSelect}
                            onChange={handleLanguageChange}
                        />
                        <Field
                            multi
                            label={<Trans>Tjänster att visa</Trans>}
                            className="form-component"
                            name="serviceIdsToShow"
                            options={servicesOptions}
                            component={RFReactSelect}
                            placeholder={<Trans>Alla</Trans>}
                        />
                        <Field
                            multi
                            label={<Trans id="Configurator.selectArticlesToShow" />}
                            className="form-component"
                            name="articleIdsToShow"
                            options={articlesOptions}
                            component={RFReactSelect}
                            placeholder={<Trans>Alla</Trans>}
                        />
                        {selectedCompanyId && (
                            <Field
                                options={preselectedServiceOptions}
                                label={<Trans>Förvald tjänst</Trans>}
                                name="selectedService"
                                className="form-component"
                                component={RFReactSelect}
                                isClearable
                            />
                        )}
                    </div>
                </Card>
                <h6>
                    <Trans>Företagssetup</Trans>
                </h6>
                <Card body style={{ marginBottom: 10 }}>
                    <label htmlFor="color-picker">
                        <Trans>Färgkod</Trans>{' '}
                        <ColorPicker
                            id="color-picker"
                            color={formValues?.primaryColor}
                            onChange={handlePrimaryColorChange}
                        />
                    </label>
                    <Field
                        label={<Trans>Visa sammanfattning av företag</Trans>}
                        className="form-component"
                        name="showCompanySummary"
                        type="checkbox"
                        component={Checkbox}
                    />
                    <Field
                        label={<Trans>Mörkt tema</Trans>}
                        className="form-component"
                        name="darkTheme"
                        type="checkbox"
                        component={Checkbox}
                    />
                    <Field
                        label={<Trans>Betalningsbekräftelse url</Trans>}
                        className="form-component"
                        name="paymentConfirmationURL"
                        type="text"
                        component={Input}
                    />
                    <Field
                        label={
                            <>
                                <Trans id="targetOriginUrl" />
                                <span style={{ color: 'red' }}>*</span>
                            </>
                        }
                        className="form-component"
                        required
                        name="targetOrigin"
                        type="text"
                        normalize={(val) => {
                            let url = val;
                            try {
                                url = new URL(val).origin;
                            } catch (error) {
                                // Do nothing
                            }
                            return url;
                        }}
                        component={Input}
                    />
                    <em><Trans id="configurator.copyPasteUrl" /></em>
                </Card>
                <h6>
                    <Trans>Egna översättningar</Trans>
                </h6>
                <Card body style={{ marginBottom: 10 }}>
                    <Trans>Egna översättningar</Trans>
                    <FieldArray
                        name="translations"
                        component={renderTranslations}
                        selectedLanguage={language}
                    />
                </Card>
                <h6>
                    <Trans>Advanced Settings</Trans>
                </h6>
                <Card body style={{ marginBottom: 10 }}>
                    <Field
                        label={<Trans>Top offset</Trans>}
                        className="form-component"
                        name="topOffset"
                        type="number"
                        component={Input}
                    />
                    <Field
                        label={<Trans>Prevent autoscroll</Trans>}
                        className="form-component"
                        name="preventAutoscroll"
                        type="checkbox"
                        component={Checkbox}
                    />
                    <Field
                        label={<Trans id="configurator.scrollAfterStep.label" />}
                        className="form-component"
                        name="scrollAfterStep"
                        type="number"
                        component={Input}
                    />
                </Card>
            </div>
        </form>
    );
};

const renderTranslations = ({ fields, selectedLanguage }) => (
    <div>
        {fields.map((field, index) => (
            <div key={index} className={cx('translation')}>
                <div className={cx('translation-key')}>
                    <Field
                        name={`${field}.key`}
                        className="form-component"
                        component={RFReactSelect}
                        options={Object.keys(translations).map((key) => {
                            const [sv, en] = translations[key];
                            const translationForSelectedLanguage =
                                selectedLanguage === 'en' ? en : sv;
                            return {
                                value: key,
                                label: `${key} (${translationForSelectedLanguage})`,
                            };
                        })}
                    />
                </div>
                <div className={cx('translation-key')}>
                    <Field
                        name={`${field}.value`}
                        className="form-component"
                        type="text"
                        component={Input}
                    />
                </div>
                <div className={cx('translation-delete')}>
                    <Button type="button" onClick={() => fields.remove(index)}>
                        ✕
                    </Button>
                </div>
            </div>
        ))}
        <Button type="button" onClick={() => fields.push({})}>
            <Trans>Lägg till översättning</Trans>
        </Button>
    </div>
);

function mapStateToProps(state, props) {
    const selector = formValueSelector('configuration');
    const selectedCompanyId = selector(state, 'company');
    const selectedBookLayout = selector(state, 'bookLayout');
    const selectedTimesLayout = selector(state, 'timesLayout');
    const selectedLanguage = selector(state, 'language'); 

    return {
        form: 'configuration',
        asyncValidate: (values) => {
            return new Promise((resolve, reject) => {
                try {
                    const url = new URL(values.targetOrigin);
                } catch (error) {
                    reject({ targetOrigin: <Trans id="configurator.pleaseEnterValidUrl" /> })
                }
            });
        },
        asyncBlurFields: [ 'targetOrigin' ],
        validate: (values) => {
            const errors = {}

                if(!values.targetOrigin) {
                    errors.targetOrigin = <Trans id="configurator.fieldRequired" />;
                }

                return errors;
        },
        asyncChangeFields: ['targetOrigin'],
        selectedCompanyId,
        selectedBookLayout,
        selectedTimesLayout,
        selectedLanguage,
        isPristine: isPristine('configuration')(state),

        initialValues: getFormInitialValues(props.location.search),
    };
}

const validate = (values) => {
    const errors = {};
    if (values.paymentConfirmationURL) {
        if (!values.paymentConfirmationURL.match(URL_REGEX)) {
            errors.paymentConfirmationURL = 'Urlen är inte gitlig';
        }
    }
    return errors;
};

Configurator = reduxForm({
    enableReinitialize: false,
    validate,
    onChange: (values, dispatch, props) => {
        const urlParameters = {
            company: values.company,
            bookMethods: values.bookMethods ? values.bookMethods.join(',') : undefined,
            serviceIdsToShow: values.serviceIdsToShow
                ? values.serviceIdsToShow.join(',')
                : undefined,
            articleIdsToShow: values.articleIdsToShow
            ? values.articleIdsToShow.join(',')
            : undefined,
            bookLayout: values.bookLayout,
            timesLayout: values.timesLayout,
            listingLayout: values.listingLayout,
            showNextAvailableTime: values.showNextAvailableTime,
            showCompanySummary: values.showCompanySummary,
            hideServiceImage: values.hideServiceImage,
            hideServiceInfo: values.hideServiceInfo,
            showEndTimeOnTimeslots: values.showEndTimeOnTimeslots,
            selectedService: values.selectedService,
            bookedTimeSlotText: values.bookedTimeSlotText,
            darkTheme: values.darkTheme,
            preventAutoscroll: values.preventAutoscroll,
            topOffset: values.topOffset,
            paymentConfirmationURL: values.paymentConfirmationURL,
            language: values.language,
            primaryColor: values.primaryColor,
            scrollAfterStep: values.scrollAfterStep,
            translations:
                values.translations && values.translations.length > 0
                    ? JSON.stringify(
                          values.translations.reduce(
                              (acc, message) => ({ ...acc, [message.key]: message.value }),
                              {}
                          )
                      )
                    : undefined,
        };

        const { history, location } = props;

        props.history.replace({
            pathname: location.pathname,
            search: `?${queryString.stringify(urlParameters)}`,
        });
    },
})(Configurator);

Configurator = withRouter(connect(mapStateToProps)(Configurator));

export default Configurator;
